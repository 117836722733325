import React from 'react'

function WhatWeOffer() {
  return (
    <div className='container width'>
      <div>
        <h2>What We Offer</h2>
        <p style={{ borderLeft: 'var(--blue) 5px solid', padding: '1rem'}}>
          At Upbeat Music Studio, we offer lessons for all ages and levels in Piano, Viola, Violin, Cello, and Voice. We also offer lessons in music theory and ear training. Whether you are a beginner or have been playing an instrument for years, we have a program that will help you reach your musical goals. Learn to play your favorite instrument today!
        </p>
      </div>
      <div className='instruments'>
        <div>
          <h4>Viola</h4>
          <img src='/assets/images/viola.jpg' alt='Viola' />
        </div>
        <div>
          <h4>Piano</h4>
          <img src='/assets/images/piano.jpg' alt='Piano' />
        </div>
        <div>
          <h4>Violin</h4>
          <img src='/assets/images/violin.jpg' alt='Violin' />
        </div>
        <div>
          <h4>Conducting</h4>
          <img src='/assets/images/conducting.jpg' alt='Voice' />
        </div>
        <div>
          <h4>Cello</h4>
          <img src='/assets/images/cello.jpg' alt='Cello' />
        </div>
        <div>
          <h4>Voice</h4>
          <img src='/assets/images/singing.jpg' alt='Voice' />
        </div>
      </div>
    </div>
  )
}

export default WhatWeOffer