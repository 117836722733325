import React from 'react'
import { Link } from 'react-router-dom'
import { FaGuitar } from "react-icons/fa";
import { GiViolin } from "react-icons/gi";
import { GiGrandPiano } from "react-icons/gi";

function Recitals() {
  return (
    <>
      <div className='recitals-container container width'>
        <div style={{ textAlign: 'center' }}>
          <h2>Recitals</h2>
          <p>Join us for our upcoming recitals and performances.</p>
        </div>
        <div className='upcoming-container'>
          <div>
            <h3>Upcoming Recitals</h3>
            <ul>
              <li><GiGrandPiano />Next Recital - <b>Saturday, Feb. 22, 2025</b> at South Davis Library in Bountiful, Utah. <a href="https://g.co/kgs/ht9AZvk" target='_blank' rel='noopener norefferrer'>Click for directions</a></li>
              {/* <li><GiViolin />Summer Recital - <b>August 21, 2024</b> at Liberty Hills Academy. <a href="https://g.co/kgs/W8ho6fL" target='_blank' rel='noopener norefferrer'>Click for directions</a></li> */}
              {/* <li><GiViolin />Fall Recital - <b>November 20, 2024</b> at Liberty Hills Academy. <a href="https://g.co/kgs/W8ho6fL" target='_blank' rel='noopener norefferrer'>Click for directions</a></li> */}
            </ul>
            <p><b>Contact me for more details!</b></p>
          </div>
          <Link to="/contact"><button className='btn'>Contact</button></Link>
        </div>
      </div>
    </>
  )
}

export default Recitals