// src/App.jsx
import React from 'react';
import AppRoutes from './routes/Routes';

function App() {
  return (
    <>
      <AppRoutes />
    </>
  );
}

export default App;
