import React from 'react';

const Footer = () => {
  return (
    <div className='footer-container'>
      <p>&copy; {new Date().getFullYear()} Upbeat Music Studio. All rights reserved.</p>
    </div>
  );
};

export default Footer;
