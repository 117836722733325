import React from 'react';
import { Link } from 'react-router-dom';
import { BsMusicNoteList } from "react-icons/bs";
import { IoEarOutline } from "react-icons/io5";
import { GiMusicalScore } from "react-icons/gi";
import { FaTheaterMasks } from "react-icons/fa";
import { FaAward } from "react-icons/fa";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { IoCheckmarkDoneSharp } from "react-icons/io5";

const About = () => {
  return (
    <div className='about-container width'>
      <h1>About</h1>
      <div className='about'>
        <div>
          <h2>Lessons by Lillian Andersen Severinsen</h2>
          <p>
            I am a professional musician with over 25 years of experience. I have a Master's degree in music education and have taught students of all ages and levels, on various instruments. I am passionate about music and love sharing my knowledge with others.
          </p>
          <br />
          <p>
            Whether you have been playing an instrument for years or are just starting out, I can help you reach your musical goals. I offer lessons in Piano, Viola, Violin, Cello, Conducting, and Voice. I also offer lessons in music theory and ear training. I believe that music is for everyone and I strive to make my lessons fun and engaging for all of my students.
          </p>
        </div>
        <img src='/assets/images/lillian.JPEG' alt='Teacher' />
      </div>
      <div className='skills-and-qualifications'>
        <div>
          <h4>Skills</h4>
          <ul>
            <li><BsMusicNoteList />Music Theory</li>
            <li><IoEarOutline />Ear Training</li>
            <li><GiMusicalScore />Composition</li>
            <li><FaTheaterMasks />Performance</li>
          </ul>
        </div>
        <div>
          <h4>Qualifications</h4>
          <ul>
            <li><FaAward />Master's Degree in Music</li>
            <li><IoIosCheckmarkCircle />15 Years Teaching Experience</li>
            <li><IoCheckmarkDoneSharp />Director of Tabernacle Choir 2016</li>
            <li><FaTheaterMasks />100+ Performances</li>
          </ul>
        </div>
      </div>
      <div className='pricing'>
        <div>
          <h2>Pricing</h2>
          <p>
            I offer lessons for Piano, Viola, Violin, Cello, Conducting, and Voice. I offer discounts for multiple lessons per week and for referrals. Contact me for more details.
          </p>
          <br />
          <p><b>One hour lesson:</b> $70</p>
          <p><b>30 minute lesson:</b> $40</p>
          <Link to="/contact"><button className='btn'>Contact</button></Link>
        </div>
        <img src='/assets/images/cello-happy.jpg' alt='Cello' />
      </div>
    </div>
  );
};

export default About;
