import React from 'react';

const Contact = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
    // Since there's no backend, you can handle form submissions via email links or third-party services like Formspree
    alert('Form submitted!');
  };

  return (
    <>
      <h1 style={{ paddingTop: '2rem'}}>Contact</h1>
      <div className='container width contact'>
        <div className='contact-form' onSubmit={handleSubmit}>
          <h4>For inquiries, please fill out the form below.</h4>
          <label htmlFor="name">Name:</label>
          <input type="text" id="name" name="name" required />
          
          <label htmlFor="email">Email:</label>
          <input type="email" id="email" name="email" required />
          
          <label htmlFor="message">Message:</label>
          <textarea id="message" name="message" rows="5" required></textarea>
          
          <button type="submit">Send Message</button>
        </div>
        <div className='contact-info'>
          <div>
            {/* <h2>Contact Information</h2> */}
            <p><b>Email:</b> lseverinsen@yahoo.com</p>
            <p><b>Phone:</b> <a href="tel:8018709075">801-870-9075</a></p>
            <p><b>Contact us today to tune into your musical journey!</b></p>
          </div>
          <img src='/assets/images/guitar.jpg' alt='Guitar' />
        </div>
      </div>
      <div className='thank-you'>
        <h4>Thank You</h4>
      </div>
    </>
  );
};

export default Contact;
