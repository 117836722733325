import React from 'react';
import Recitals from '../components/Recitals';
import PastRecitals from '../components/PastRecitals';
import WhatWeOffer from '../components/WhatWeOffer';

const Home = () => {
  return (
    <>
      <div className='container home'>
        <h1>Upbeat Music Studio</h1>
        <h3>Music Lessons In Bountiful, Utah</h3>
        <h4>Tune In to Your Musical Journey</h4>
        <img src='/assets/images/happy.jpg' alt='Happy Singing' />
      </div>
      <WhatWeOffer />
      <Recitals />
      {/* <PastRecitals /> */}
    </>
  );
};

export default Home;
