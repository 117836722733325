import React from 'react';
import { Link } from 'react-router-dom';
import { GiMicrophone } from "react-icons/gi";
import { FaYoutube } from "react-icons/fa";

const Header = () => {
  return (
    <div className='header-container'>
      <div className='nav'>
        <div>
        <Link to="/"><GiMicrophone style={{ fontSize: '2.5rem', border: 'var(--light) 1px solid', borderBottomRightRadius: '30%', padding: '0.2rem' }} /></Link>
          {/* <GiMicrophone style={{ fontSize: '2.8rem' }}/> */}
        </div>
        <div className='links'>
          <Link to="/">Home</Link>
          <Link to="/about">About</Link>
          <Link to="/contact">Contact</Link>
        </div>
        <div>
          <a href='' target='_blank' rel='noopener norefferrer'><FaYoutube className='youtube'/></a>
        </div>
      </div>
    </div>
  );
};

export default Header;
